<template>
  <div>
    <div class="card card-custom gutter-b card-stretch mt-2">
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label text-white font-weight-bolder">User Profile</span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm">แก้ไขข้อมูลส่วนตัว</span>
        </h3>
        <div class="card-toolbar">
        </div>
      </div>
      <div class="card-body pt-2">
        <div class="mb-4">
          <div>Name</div>
          <b-form-input v-model="profile.name" />
        </div>
        <div class="mt-6">
          <b-button @click="callUpdate">Update</b-button>
        </div>
      </div>
    </div>
    <div class="card card-custom gutter-b card-stretch mt-2">
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label text-white font-weight-bolder">Upload New Avatar</span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm">อัพโหลดรูปแทนตัว</span>
        </h3>
        <div class="card-toolbar">
        </div>
      </div>
      <div class="card-body pt-2">
        <div class="mb-4">
          <croppa
            v-model="avatar"
            :prevent-white-space="true"
            :width="100"
            :height="100"
            placeholder=""
            canvas-color="#000"
            :quality="1"
          />
        </div>
        <div class="mt-6">
          <b-button @click="callAvatar" :disabled="!avatarupload">Upload</b-button>
        </div>
      </div>
    </div>
    <div class="card card-custom gutter-b card-stretch mt-2" v-if="password.changable">
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label text-white font-weight-bolder">Password</span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm">เปลี่ยนรหัสผ่าน</span>
        </h3>
        <div class="card-toolbar">
        </div>
      </div>
      <div class="card-body pt-2">
        <div class="mb-4" v-if="!password.new">
          <div>Current Password</div>
          <b-form-input v-model="password.current" type="password" />
        </div>
        <div class="mb-4">
          <div>New Password</div>
          <b-form-input v-model="password.newpass" type="password" />
        </div>
        <div class="mb-4">
          <div>Confirm Password</div>
          <b-form-input v-model="password.confirm" type="password" />
        </div>
        <div class="mt-6">
          <b-button @click="callChange">Change Password</b-button>
        </div>
      </div>
    </div>
  </div>  
</template>

<script>
import { UPDATE_NAME, UPDATE_IMG } from "@/core/services/store/auth.module";

export default {
  data () {
    return {
      profile: {
        name: ''
      },
      password: {
        changable: false,
        new: false,
        current: '',
        newpass: '',
        confirm: ''
      },
      avatar: {},
      uploadingavatar: false
    }
  },
  methods: {
    recvData () {
      this.axios.get('/profile').then(
        ({ data }) => {
          this.profile.name = data.name
          this.password.changable = data.changepass
          this.password.new = data.newpass
        }
      )
    },
    callUpdate () {
      this.axios.post('/profile/changename', { name: this.profile.name }).then(
        ({ data }) => {
          this.$store.dispatch(UPDATE_NAME, data.name)
        }
      )
    },
    callChange () {
      var param = {
        current: this.password.current,
        newpass: this.password.newpass,
        confirm: this.password.confirm
      }

      this.axios.post('/profile/changepassword', param).then(
        ({ data }) => {
          this.password.current = ''
          this.password.newpass = ''
          this.password.confirm = ''
        }
      )
    },
    callAvatar () {
      if (!this.avatar.hasImage()) {
        return
      }

      if (this.uploadingavatar) {
        return
      }

      this.avatar.generateBlob((blob) => {
        var fd = new FormData()
        fd.append('file', blob)

        this.axios.post('/profile/avatar', fd).then(
          ({ data }) => {
            console.log(data)
            this.avatar.remove()
            this.$store.dispatch(UPDATE_IMG, data.url)
          }
        ).finally(() => {
          this.uploadingavatar = false
        })
      }, 'image/jpeg', 0.9)
    }
  },
  computed: {
    avatarupload() {
      return this.avatar != null && this.avatar.width != null && this.avatar.hasImage()
    }
  },
  mounted () {
    this.recvData()
  }
}
</script>
